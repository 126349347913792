import { graphql, PageRendererProps } from "gatsby"
import React from "react"
import { Query } from "@/graphql-types"
import { Title, PostContainer } from "@/styles"
import Layout from '@/components/layout'

interface Props extends PageRendererProps {
    data: Query;
}

const About: React.FunctionComponent<Props> = ({ data, location }) => {
    const content = data.markdownRemark!
    return (
        <Layout location={location}>
            <Title>{ content.frontmatter!.title }</Title>
            <PostContainer dangerouslySetInnerHTML={{ __html: content.html! }}></PostContainer>
        </Layout>
    );
}

export default About

export const pageQuery = graphql`
    query {
        markdownRemark(fields: { category: { eq: "pages" }, slug: { eq: "about" } }) {
            id
            html
            frontmatter { title }
        }
    }
`